import { createGlobalStyle } from 'styled-components'

export const GlobalBackgroundColors = {
  bgPrimary: "#000",
  bgSecondary: "#E6E6E6",
  bgGreyDark: "#383e40",
  bgGreyLight: "#f8f8f8",
  //bgContentLight: `#F67879`,
  bgContentLight: `#A22B38`,
  bgContentLightest: `#FDDAD2`,
  bgColorApp: `#f7f9fc`,
  bgColorHeader: `#ffffff`,
  bgColorMenu: `#f7f9fc`,
  bgColorMain: `#ffffff`,
  bgColorFrame: `#f7f9fb`,
  bgColorTextFound: `#f39c12`
}

export const GlobalColors = {
  colorMenuPrimary: `#FFFFFF`,
  colorMenuSecondary: `#1D1D1B`,
  colorBorder: `#e7ecf1`,
  colorBorderDark: `#9da1a4`,
  colorPrimary: `#1D1D1B`,
  colorPrimaryLight: `#f1c40f`,
  colorPrimaryDark: `#e67e22`,
  colorSecondary: `#FFFFFF`,
  colorSuccess: `#20bf6b`,
  colorDanger: `#eb3b5a`,
  colorDangerDark: `#c5314b`,
  colorWarning: `#f7b731`,
  colorInfo: `#8854d0`,
  colorLight: `#f7f9fc`,
  colorDark: `#4b6584`,
  colorShadowRGB: `75, 101, 132`,
  colorLink: `#eb3b5a`,
  colorLinkHover: `#eb3b5a`
}

export const GlobalFonts = {
  fontPrimary: 'NunitoSansRegular',
  fontSecondary: 'TravelingTypewriter',
  fontLight: 'NunitoSansLight',
  fontRegular: 'NunitoSansRegular',
  fontMedium: 'NunitoSansSemiBold',
  fontBold: 'NunitoSansSemiBold',
}

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    background: ${GlobalBackgroundColors.bgSecondary};
    overflow: auto;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  ul, li, h1, h2, h3, p, button { margin: 0; padding: 0; }
  ul { list-style: none; }
  button { background: transparent; border: 0; outline: 0 }
  *:focus {
    outline: none;
  }
  body {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    overscroll-behavior: none;
  }
  #root{
    height: 100vh;
  }
  .router{
    position: unset !important;
  }
  .wrapper{
    height: 100%;
  }
  
  .margin-no{
    margin: 0;
  }
  .padding-no{
    padding: 0;
  }
  
  .padding-3{
    padding: 3px;
  }
  
  .cont-page,
  .alto-100,
  .full-height{
    height: 100%;
  }
  
  #divTransitionPage {
    position: fixed;
    top: 0;
    left: 0;
  
    width: 0;
    height: 100vh;
  
    z-index: 99999;
    transition: width 0.5s;
  }
  .clsTransitionPageIn{
    width: 100% !important;
    
    background: red;
  }
  .clsTransitionPageOut{
    right: 0;
    
    width: 0 !important;
  }
  
  #app {
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    overflow-x: hidden;
    min-height: 100vh;
    padding-bottom: 10px;
  }
  .field-label{
    flex-grow: 2;  
  }
  
  .container.is-fluid{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .columns {
    margin-top: 0 !important;
  }
  .columns:not(:last-child) {
    margin-bottom: 0 !important;
  }
  .row-reverse{
    display: flex;
    flex-direction: row-reverse;
  }
  .columns-paddign-no{
    @media(min-width: 300px) and (max-width: 768px){
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
  .columns-margin-side-no{
    margin-left: 0px !important;
    margin-right: 0px !important
  }
  
  .global-columns-paddign-no{
    padding: 0px !important;
  }
  .overflow-unset{
    overflow: unset !important;
  }
  
  .flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .transition-group {
    flex: 1;
    position: relative;
  }
  
  .router {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
    
  .fade-enter {
    opacity: 0;
    z-index: 1;
  }
  
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 350ms ease-in;
  }
  
  .dark{
    background-color: ${GlobalBackgroundColors.bgPrimary};
  }
  .light{
    background-color: ${GlobalBackgroundColors.bgSecondary};
  }
  .fontColorThemeDark{
    color: ${GlobalBackgroundColors.bgSecondary};
  }
  .fontColorThemeLight{
    background-color: ${GlobalBackgroundColors.bgPrimary};
  }
  
  
  @media(min-width: 300px) and (max-width: 1024px){
    .columns {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .columns:last-child {
      margin-bottom: 0;
    }
  }
  @media(max-width: 768px){
    .column.is-5-mobile,
    .column.is-6-mobile{
      padding-top: .75rem;
      float: left;
    }
  }
  
  @media (min-width: 1023px){
    .display-mobile{
      display: flex !important;
    }
  }
  @media (max-width: 1023px){
    .columns-responsive{
      
    }
    .no-display-mobile{
      display: none !important;
    }
  }
  @media (min-width: 1024px){
    .no-display-desk{
      display: none;
    }
  }
`
