import React from 'react';
import PropTypes from "prop-types"
import { Container } from 'bloomer';
import { GlobalStyleLayout } from './Layout.styles';
import { GlobalStyle } from '../../styles/GlobalStyles'
import { GlobalFonts } from '../../styles/fonts';

import '../../styles/bulma/css/bulma.min.css';

const Layout = ({ children, clsOverflow}) => {

  return (
    <div className={`layout ${clsOverflow ? 'layout-overflow-on' : 'layout-overflow-off' } `}>
      <GlobalStyle />
      <GlobalFonts />
      <GlobalStyleLayout/>

      <Container isFluid className="wrapper-pages">
        {children}
      </Container>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
