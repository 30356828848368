/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ title, description, works, lang, meta, bolImage, ogImage, twitterImage}) {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData{
        site {
          siteMetadata {
            title
            siteUrl
            description
            linkFacebook
            linkInstagram
            twitterSite
            author
          }
        }
      }
    `
  )

  //console.log('site.siteMetadata => ', site.siteMetadata);

  const metaTitle       = site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  const metaUrl         = site.siteMetadata.siteUrl;
  const metaTwitterSite = site.siteMetadata.twitterSite;

  ogImage      = bolImage === 1 ? metaUrl + '/images/metas/' + ogImage : ogImage;
  twitterImage = bolImage === 1 ? metaUrl + '/images/metas/' + twitterImage : twitterImage;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={`${title}`}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `robots`,
          content: 'all',
        },
        {
          name: `lang`,
          content: 'es',
        },
        {
          property: `keywords`,
          content: works,
        },
        {
          property: `og:title`,
          content: `${title} | ${metaTitle}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: metaTitle,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:local`,
          content: 'en_US',
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: `${title} | ${metaTitle}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:url`,
          content: metaUrl,
        },
        {
          name: `twitter:image`,
          content: twitterImage,
        },
        {
          name: `twitter:site`,
          content: metaTwitterSite,
        },
        {
          name: `twitter:creator`,
          content: '@guzbarraf',
        },
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
