import {createGlobalStyle} from 'styled-components'
import imgBG from '../../images/bg.jpg';

export const GlobalStyleLayout = createGlobalStyle`
  .layout{
    /*height: calc(100vh - 80px);*/
    height: 100vh;
    /*contain: content;*/
    background-image: url('${imgBG}');
    background-repeat: no-repeat;
    background-size: cover;
    @media(min-width: 1024px){
      contain: content;
    }
  }
  .wrapper-pages{
    position: relative;
    padding-top: 0;
    height: calc(100vh - 95px);
    bottom: 0;
    z-index: 999;
    
    @media(min-width: 1024px) and (max-width: 1999px){
      height: calc(100vh - 95px);
    }
  }
  .layout-overflow-on{
    overflow: auto;
  }
  .layout-overflow-off{
    overflow: hidden;
  }
`
